import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["consignee", "lineItem"];


  /////////////////
  //// Actions ////
  /////////////////


  //Update each line-item's consignee parameter when consignee changes
  consigneeChanged(event) {
    const consignee = this.consigneeTarget.value;
    // Loop through each line-item form and trigger the update
    this.lineItemTargets.forEach((lineItem) => {
      const controller = Stimulus.getControllerForElementAndIdentifier(lineItem, 'delivery-orders--line-item')
      if (controller && controller.updateParams) {
        controller.updateParams(consignee);
      }
    });
  }
}
