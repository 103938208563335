import { Controller } from "@hotwired/stimulus"
import $ from "jquery"

export default class extends Controller {
  static targets = ["cargoType", "cargo"]

  ///////////////////
  //// Callbacks ////
  ///////////////////

  connect() {
    this.updateParams();
  }

  /////////////////
  //// Actions ////
  /////////////////

  updateCargoParams(event) {
    this.updateParams();
  }


  /////////////////
  //// Helpers ////
  /////////////////


  // Checks for values presense and update the params for cargo autocomplete
  updateParams(consignee = null) {
    const cargoType = this.cargoTypeTarget.value;
    const consigneeId = consignee || $('#delivery_order_consignee_id').val();

    // Retrieve existing params or default to an empty object
    const existingParams = this.cargoTarget.dataset.params
      ? JSON.parse(this.cargoTarget.dataset.params)
      : {};

    // Merge cargo_type and consignee_id dynamically
    const newParams = {
      ...existingParams,
      ...(cargoType && { cargo_type: cargoType }),
      ...(consigneeId && { consignee_id: consigneeId }),
    };

    // Save updated params
    this.cargoTarget.dataset.params = JSON.stringify(newParams);
  }
}
